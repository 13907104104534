:root {
  --app-height: 100%;
}

#root {
   height: 100vh;

   @media not all and (hover:hover) {
       height: var(--app-height);
   }
}

// change the theme
$theme-colors: (
  "custom": #00d95f,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss'