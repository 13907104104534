body {
    background-color: #f2f7f5;
    color: #475d5b;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #00473e;
}

.album-img {
    width: 100%;
    border: solid 1px;
}

.album-container {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    padding: 5px;
}

.album-tile {
    display: flex;
    flex-direction: column;
}

.album-tile:hover .album-name {
    white-space: normal;
}

.album-tile-button {
    width: 100%;
    padding: 5px;
}

.album-tile-button:hover {
    background-color: #a5eeaf;
}

.album-name {
    font-size: smaller;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.card-header {
    padding: 5px;
}

.card-header .btn-link {
    color: inherit;
}

footer {
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
}

.main {
    flex: 1 0 auto;
}

.game-album {
    width: 100%;
}

/* addressing game button hover on mobile*/

.btn-outline-secondary:hover {
    color: inherit;
    background-color: inherit;
    border-color: inherit;
}

@media (hover: hover) {
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
    }
}

/* end of section */

.btn:active,
.btn:focus {
    border-color: none;
    box-shadow: none;
}

.form-control:focus,
.btn-link:focus {
    border-color: #a5eeaf;
    box-shadow: 0 0 0 0.2rem rgba(148, 199, 157, 0.5);
}

#game-status {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
}

a,
a:hover {
    color: #00d95f;
}

.btn-auth,
.btn-auth:hover {
    color: inherit;
}

.btn-light {
    background-color: inherit;
    border: none;
}

.ratio-maintainer-wrapper {
    background-color: #ffffff;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative;
}

.ratio-maintainer-inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    align-items: center;
    display: flex;
}

#landing-logo {
    max-width: 70%;
    max-height: 70%;
}

#landing-wrapper {
    text-align: center;
    padding-top: 0;
}

@media (min-width: 576px) {
    #landing-wrapper {
        padding-top: 95px;
    }
}

#slogan {
    position: relative;
    bottom: 95px;
    display: none;
}

@media (min-width: 576px) {
    #slogan {
        display: block;
    }
}
